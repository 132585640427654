'use client'
import { OptimizedImage } from '@/app/ui/parts/default/common/originalImage'
/* eslint-disable @typescript-eslint/no-misused-promises */
import { itemType } from '@/lib/item/server'
import { deleteLike } from '@/lib/like'
import { serviceType } from '@/lib/service'

export const DefaultItemCard = ({
  item,
  service,
  isLiked,
  index,
}: {
  item: itemType
  service: serviceType
  main?: boolean
  isLiked?: boolean
  index?: number
}) => {
  return (
    <div className="item-card w-1/2 p-1 min-w-[170px] md:w-1/3 lg:w-1/4 xl:w-1/5">
      <div className="item-card-container h-44 relative bg-white">
        <a href={`/item/${item.item_id}`} className="item-card-image-container">
          <OptimizedImage
            src={item.image_url}
            alt={item.name}
            height={200}
            width={200}
            className="item-card-image w-full h-full object-cover"
          />
        </a>
        {(item.discounted_price > 0 || item.discount_rate > 0) && (
          <div
            className="item-card-image-sale main-color absolute bottom-1 left-1 rounded-md w-12 h-8 flex justify-center items-center text-white"
            style={{
              padding: '6px 11px',
              borderRadius: '26px',
            }}
          >
            <p className="text-xs font-bold">SALE</p>
          </div>
        )}
        {item.is_popular && !index && (
          <div
            className="item-card-image-popular main-color absolute top-0 right-0 rounded-bl-full text-white"
            style={{
              padding: '3px 3px 11px 11px',
            }}
          >
            <p className="item-card-image-popular-text text-sm font-bold">
              人気
            </p>
          </div>
        )}
        {index && index > 0 && index <= 100 && (
          <div
            className="item-card-image-ranking main-color absolute top-0 left-0 rounded-br-full text-white"
            style={{
              padding: '4px 15px 12px 8px',
            }}
          >
            <p className="font-bold text-xl">
              {index}
              <span className="ml-1 text-sm">位</span>
            </p>
          </div>
        )}
        {isLiked && service && (
          <div
            className="absolute right-1 top-1 rounded-full w-9 h-9 flex justify-center items-center z-10"
            style={{ backgroundColor: '#999', color: '#fff' }}
            onClick={async () => {
              await deleteLike(service.service_id, item.item_id)
            }}
          >
            <p className="text-xl">×</p>
          </div>
        )}
      </div>
      <a className="item-description-container" href={`/item/${item.item_id}`}>
        <div className="item-card-description-container bg-white p-2 overflow-hidden leading-snug min-h-[88px]">
          <p className="item-card-description-price font-black text-base mb-1 font-bold">
            ¥{item.price.toLocaleString()}{' '}
            <span className="item-card-description-tax font-light text-sm">
              (税込)
            </span>
          </p>
          {!item.stock && (
            <p className="text-sm text-[#4f5256] font-bold ml-1">売り切れ</p>
          )}
          <div className="item-card-description-text text-[13px] text-black max-h-[35px] overflow-hidden my-1">
            <h3>{item.name}</h3>
          </div>
          {item.tags && item.tags.length > 0 && (
            <div className="item-card-tag-list hidden bg-white py-1">
              <div className="flex overflow-x-auto whitespace-nowrap">
                {item.tags.map((tag) => (
                  <div
                    key={tag.tag_id}
                    className={
                      `tag-${tag.tag_id}` +
                      ' bg-[#f1f1f1] text-[#4f5256] text-[11px] px-1 py-1 mr-1 mt-1 rounded-sm'
                    }
                  >
                    {tag.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {item.sizes && item.sizes.length > 0 && (
            <div className="item-card-size-list hidden bg-white py-1">
              <div className="flex overflow-x-auto whitespace-nowrap">
                {item.sizes.map((size) => (
                  <div
                    key={size.size_id}
                    className={
                      `size-${size.size_id}` +
                      ' bg-[#f1f1f1] text-[#4f5256] text-[11px] px-1 py-1 mr-1 mt-1 rounded-sm'
                    }
                  >
                    {size.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {item.colors && item.colors.length > 0 && (
            <div className="item-card-color-list hidden bg-white py-1">
              <div className="flex overflow-x-auto whitespace-nowrap">
                {item.colors.map((color) => (
                  <div
                    key={color.color_id}
                    className={
                      `color-tab-${color.color_id}` +
                      ' bg-[#f1f1f1] text-[#4f5256] text-[11px] px-1 py-1 mr-1 mt-1 rounded-sm'
                    }
                  >
                    {color.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {item.colors && item.colors.length > 0 && (
            <div className="item-card-color-code-list hidden bg-white py-1 flex items-center justify-between">
              <div className="flex">
                {item.colors.map((color) => (
                  <div
                    key={color.color_id}
                    className={
                      `color-${color.color_id}` +
                      ' hidden h-[13px] w-[13px] rounded-full mr-1 mt-1'
                    }
                  ></div>
                ))}
              </div>
              <div className="item-card-color-count text-[#4f5256] text-[11px] mr-1">
                全{item.colors.length}色
              </div>
            </div>
          )}
          {item.item_images && item.item_images.length > 0 && (
            <div className="item-card-image-list hidden bg-white py-1">
              <div className="flex overflow-x-auto whitespace-nowrap">
                {item.item_images.map((image) => (
                  <div
                    key={image.image_id}
                    className={
                      `image-${image.image_id} ` +
                      'h-[40px] min-w-[40px] mr-1 mt-1'
                    }
                  >
                    <OptimizedImage
                      src={image.image_url}
                      alt={item.name}
                      height={40}
                      width={40}
                      style={{
                        objectFit: 'cover',
                        borderRadius: '4px',
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </a>
    </div>
  )
}
