import Image from 'next/image'

interface OptimizedImageProps {
  src: string
  alt: string
  width: number
  height: number
  className?: string
  style?: React.CSSProperties
  priority?: boolean
}

export const OptimizedImage = ({
  src,
  alt,
  width,
  height,
  className,
  style,
  priority = false,
}: OptimizedImageProps) => {
  const isPng = src.toLowerCase().endsWith('.png')
  const isUsedInCloudFront = src.includes('cloudfront')

  // PNGの場合はVercelの最適化を使用
  if (isPng || isUsedInCloudFront) {
    return (
      <Image
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={className}
        priority={priority}
        style={style}
        loading={priority ? 'eager' : 'lazy'}
        quality={75}
      />
    )
  }

  // PNG以外の場合は通常のimg要素を使用
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      className={className}
      style={style}
      loading={priority ? 'eager' : 'lazy'}
    />
  )
}
