/* eslint-disable @typescript-eslint/no-misused-promises */
'use client'
import { itemType } from '@/lib/item/server'
import { deleteLike, registerLike } from '@/lib/like'
import { serviceType } from '@/lib/service'
import { Heart } from 'lucide-react'
import Image from 'next/image'

export const ZenithItemCard = ({
  item,
  service,
  main,
  isLiked,
  index,
}: {
  item: itemType
  service: serviceType
  main?: boolean
  isLiked?: boolean
  index?: number
}) => {
  return (
    <div
      className={
        main
          ? 'item-card w-[33%] p-1 relative'
          : `item-card w-[39%] p-1 min-w-[160px] relative`
      }
    >
      <div className="item-card-container h-56 relative">
        <a href={`/item/${item.item_id}`} className="item-card-image-container">
          <Image
            src={item.image_url}
            alt={item.name}
            height={200}
            width={200}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            className="item-card-image"
            unoptimized
          />
        </a>
        {item.discounted_price > 0 && (
          <div
            className="item-card-image-sale absolute bottom-1 left-1 rounded-md w-12 h-8 flex justify-center items-center"
            style={{
              backgroundColor: service?.main_color,
              color: '#fff',
              padding: '6px 11px',
              borderRadius: '26px',
            }}
          >
            <p className="text-xs font-bold">SALE</p>
          </div>
        )}
        {item.is_popular && !index && (
          <div
            className="item-card-image-popular absolute top-0 right-0 rounded-bl-full"
            style={{
              backgroundColor: service?.main_color,
              color: '#fff',
              padding: '3px 3px 11px 11px',
            }}
          >
            <p className="item-card-image-popular text-sm font-bold">人気</p>
          </div>
        )}
        {index && index > 0 && index <= 100 && (
          <div
            className="item-card-image-ranking absolute top-0 left-0 rounded-br-full"
            style={{
              backgroundColor: service?.main_color,
              color: '#fff',
              padding: '4px 15px 12px 8px',
            }}
          >
            <p className="font-bold text-xl">
              {index}
              <span className="ml-1 text-sm">位</span>
            </p>
          </div>
        )}
        {isLiked && service && (
          <div
            className="absolute right-1 top-1 rounded-full w-9 h-9 flex justify-center items-center z-10"
            style={{ backgroundColor: '#999', color: '#fff' }}
            onClick={async () => {
              await deleteLike(service.service_id, item.item_id)
            }}
          >
            <p className="text-xl">×</p>
          </div>
        )}
      </div>
      {!isLiked && (
        <button
          className="item-card-like-btn absolute right-2 bottom-20 z-10"
          onClick={async () => {
            await registerLike(service.service_id, item.item_id)
            alert('お気に入りに追加しました')
          }}
        >
          <Heart strokeWidth={0.5} size={24} color="#333" fill="#fff" />
        </button>
      )}
      <div className="w-full relative">
        <a className="w-full" href={`/item/${item.item_id}`}>
          <div className="item-card-description-container bg-white p-1 h-[68px] overflow-hidden leading-snug text-left">
            <div className="item-card-description-text text-xs text-black text-left h-[34px] overflow-hidden">
              <h3 className="hidden">{item.name}</h3>
              <p className="h-[32px]">
                {item.name.includes(service.prefix)
                  ? item.name.replace(service.prefix, '').trim()
                  : item.name}
              </p>
            </div>
            <div className="w-full text-left flex justify-between">
              {item.discounted_price ? (
                <p className="item-card-description-price font-black text-sm mb-1 mt-1 font-medium">
                  ¥{item.discounted_price.toLocaleString()}
                </p>
              ) : (
                <p className="item-card-description-price font-black text-sm mb-1 mt-1 font-medium">
                  ¥{item.price.toLocaleString()}
                </p>
              )}
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}
